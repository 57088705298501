import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageCard from './ImageCard'
import { FaUser } from "react-icons/fa";
import { GiTimeBomb } from "react-icons/gi";
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CategoryAction from '../../redux/actions/CategoryAction';
import baner1 from '../../images/baner1.png';
import baner3 from '../../images/baner3.png';
import baner4 from '../../images/baner4.png';
import baner5 from '../../images/baner5.jpg';
import baner7 from '../../images/baner7.png';
const Homecarousel = ({ homecarouseldata }) => {
    const mobile = useMediaQuery({ query: '(min-width: 991px)' })
    const { getSubcategoryPostdatalistdata } = CategoryAction();
    const dispatch = useDispatch();
    const samples = [
        {
            image: baner7,

        },
        {
            image: baner5,

        },
        {
            image: baner3,

        },
        {
            image: baner4,

        },
        {
            image: baner1,

        },


    ]
    const useEffect = () => {

    }
    //   const click=(slug)=>{

    //    const input_data = {
    //     slug: slug,
    //     page: 1,
    //   };
    //   dispatch(getSubcategoryPostdatalistdata(input_data));
    //   }

    return (
        <div className='daily-deals-main-sidebar'>

            <div className='ourproducts-header-main'>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
                    spaceBetween={0}
                    slidesPerView={1}

                    effect="fade"
                    loop={true}
                    //   pagination={{ clickable: true }}
                    navigation={{
                        nextEl: ".ImageCard-next ",
                        prevEl: ".ImageCard-prev "
                    }}

                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false
                    }}

                >
                    {

                        samples.map((ele) => {
                            return (
                                <>
                                    <SwiperSlide className='ImageCard-swiper'>

                                        <img className='homecarousel-image-main-header' src={ele.image}></img>
                                        {/* <NavLink to={`/Detail/${ele.slug}`} className={"text-decoration-none"} >

                                        <ImageCard classname={"carousel_image   "}  dissize={"mainimage"} image={ele.image} discription={ele.title} author_name={ele.author_name} category_name={ele.category_name} published_date={ele.published_date} />
                          </NavLink> */}
                                        {
                                            // mobile && 
                                            <div >
                                                <div className={` ImageCard-prev  `}><BiSolidChevronLeft size="30" /></div>
                                                <div className={` ImageCard-next `}><BiSolidChevronRight size="30" /></div>
                                            </div>
                                        }
                                    </SwiperSlide>

                                </>
                            )
                        })









                    }
                    {
                        // mobile && 
                        <div >
                            <div className={` ImageCard-prev  `}><BiSolidChevronLeft size="30" /></div>
                            <div className={` ImageCard-next `}><BiSolidChevronRight size="30" /></div>
                        </div>
                    }
                </Swiper>


            </div>

        </div>
    );
};


export default Homecarousel;