import React, { useContext } from 'react';
import axios from 'axios';
// import { useCookie } from "../hooks";
// import { StateContext } from '../components/context/ManageStates';
// import Cookies from 'universal-cookie';
import Cookies from 'universal-cookie';
const Apis = () => {
  // const { getCookie } = useCookie()
  var cookies = new Cookies();

  // var cookies = new Cookies();

  // const { authStatus, authToken } = useContext(StateContext);

  // axios.defaults.baseURL = "https://admin.apnashopee.com/";
  axios.defaults.baseURL = "https://admin.shopersbay.com/";
  // axios.defaults.baseURL = "https://dev.shopersbay.com/";
  
  axios.defaults.headers.common['authentication'] = "CWDpLQ1311012WPdssW52769d731"; //poojitha
  // if (authStatus) {
  //   var config = {
  //     headers: { authorization: authToken }
  //   };
  // }
  const getMasterDataService = async () => {
    const response = await axios.get("asapi/getMasterData")
    return response;
  };
  const gvt = async (input_data) => {
    const response = await axios.post("asapi/gvt", input_data,);
    return response;
  };
  const getNewsDatalist = async () =>{
   const response =  await axios.get("asapi/getHomePageNewsData");
   return response;
  }
  const getNavigationlist = async () =>{
    const response =  await axios.get("asapi/getNavigation");
    return response;
   }
  
  const getSubcategoryPostdatalist = async(input_data)=>{
  const response =  await axios.post("asapi/getSubcategoryPostdata",input_data);
  return response;
}
 const getdetaillist = async (input_data)=>{
  const response = await axios.post("asapi/getPostsDetail",input_data);
  return response;
 }

 const getRelatedPostslist   = async (input_data)=>{
  const response = await axios.post("asapi/getRelatedPosts",input_data);
  return response;
 }
  return {
    getNewsDatalist,
    getNavigationlist,
    gvt:gvt,
    getSubcategoryPostdatalist:getSubcategoryPostdatalist,
    getdetaillist:getdetaillist,
    getRelatedPostslist:getRelatedPostslist,
    getMasterDataService:getMasterDataService,
  };
};
export default Apis;