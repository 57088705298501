import React from 'react';
import { NavLink } from 'react-router-dom';
// import { GoDotFill } from "react-icons/go";
const TitleHeader = ({ title,viewall,slug}) => {
    return (
        <div>
            <h3 className='header-main-div'>
                <span className='header-heading'>{title}</span>
                <NavLink className={"text-decoration-none"} to={`/Category/${slug}`}>
                 <div className='header-heading-viewall'>{viewall}</div>
                 </NavLink>
            </h3>
           
        </div>
    );
};

export default TitleHeader;