import React from 'react'
import Apis from "../../services/Apis";
const HomeAction =()=>{
    const { getNewsDatalist,getNavigationlist } = Apis();
    const getNewsDatalistdata = () => async dispatch => {
      dispatch({
        type: 'setloader',
        payload: true
      });
        const response = await getNewsDatalist()
   
        if (response.data.status == 'success') {
          // alert()
            dispatch({
                type: 'setHomedata',
                payload: response.data.data,
            
              });
            } else {
              dispatch({
                type: 'setHomedata',
                payload: []
              });
            }
            dispatch({
              type: 'setloader',
              payload: false
            });
        }; 
      
        const getNavigationlistdata = () => async dispatch => {
          dispatch({
            type: 'setloader',
            payload: true
          });
          const response = await getNavigationlist()
     
          if (response.data.status == 'success') {
            // alert()
              dispatch({
                  type: 'setnavdata',
                  payload: response.data.data,
              
                });
              } else {
                dispatch({
                  type: 'setnavdata',
                  payload: []
                });
              }
          }
return{
    getNewsDatalistdata:getNewsDatalistdata,
    getNavigationlistdata:getNavigationlistdata
};

}
export default HomeAction;


