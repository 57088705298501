import React, { useState } from "react";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FaPaperPlane } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { useMediaQuery } from 'react-responsive';

import imagelogo from '../../images/drpujitha_logo.webp';
import certificate from '../../images/certificate.jpeg'
import { MdLocalPhone } from "react-icons/md";
import { useSelector } from "react-redux";

const  Footer = () => {
  const head = ["news", "earth", "real", "sports", "worklife", ]
  const navdata = useSelector(state => state.masterdata.navdata);
  const homedata = useSelector(state => state.masterdata.homedata);
  const ismobile = useMediaQuery({ query: '(min-width: 991px)' })
  const [show, setShow] = useState(7);
  const [buttonShow, setButtonShow] = useState(true);
  const handelViewMore = () => {
    setShow(-1);
    setButtonShow(false);
  };

  const handelViewLess = () => {
    setShow(7);
    setButtonShow(true);
  };
  const data = [
    {
        image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
        category_name: "health",
        author_name: "Sridhar",
        discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
        published_date: "20/20/2039",
    },
    {
        image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
        category_name: "travel",
        author_name: "murali",
        discription: "Early tourists choices to the sea of Maldiv…",
        published_date: "20/20/2039",
    },
    {
        image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
        category_name: "health",
        author_name: "Sridhar",
        discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
        published_date: "20/20/2039",
    },
   



]
  return (
    <>
      <div>
      <div className="footer-content" >
        <Container>
          <Row>
            <Col lg={12} className="footer-content-div">
              {/* <div className="footer-content-section">
                Need Doctor For Check-up ?
              </div> */}
              <div className="footer-content-section">
              Just Make An Appointment Today TO Book An Appointment Today 
              </div>
              <div className="footer-content-section">
                Visit Rainbow Childerens Hospital & Birth Right - Financal District
              </div>
              <div> OR</div>
              <div className="footer-number" > <NavLink  to="tel:+917989806956" className={`text-decoration-none footer-phonenumber`} ><MdLocalPhone />79898 06956</NavLink></div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-down">
      <Col lg={4} className='header-main-div-company footer-left'>
     <div className='header-main'>
        <div className='header-name'>
        Dr.Pujitha Devi Suraneni
     </div>
     <div className='header-colification'>MBBS,MS(Obs & Gynae),FMAS,FICRS</div>
     <div className='headcontant'>Senior consultant,High-Risk Obstetrician,</div>
     <div className='headcontant'>Laparoscopic and Robotic Surgeon</div>
     </div>
   
     <div>

     </div>
     </Col>
    <Col lg={4} className="footer-certificate  footer-middle">
    <img referrerPolicy="no-referrer" className='footer-logo-image' src={certificate}></img>
    </Col>
    <Col lg={4} className="footer-certificate footer-last">
      {
        !ismobile &&
        <div className='footer-contacts'>
        <NavLink  className={"text-decoration-none"}  to="https://www.instagram.com/drpujithadevisuraneni/" target="_blank">
        <FaInstagramSquare className='header-contact-logo'/> 
        </NavLink>
        <NavLink  className={"text-decoration-none"}  to="https://www.facebook.com/DrPujithaDeviSuraneni" target="_blank">
        <FaFacebook className='header-contact-logo'/> 
        </NavLink>
        <NavLink  className={"text-decoration-none"}  to="https://www.youtube.com/@PujithaDeviSuraneni" target="_blank">
        <FaYoutube className='header-contact-logo'/> 
        </NavLink>
        </div>
      }

    <img referrerPolicy="no-referrer" className='footer-logo-image' src={imagelogo}></img>
    </Col>
      </div>
      </div>
    
    </>
  );
};

export default Footer;
