import React, { useState } from "react";
import ImageCard from "./ImageCard";
import TitleHeader from "./TitleHeader";
import { Row, Col, Modal, Button } from "react-bootstrap"; // Import Modal and Button from react-bootstrap
import { NavLink } from 'react-router-dom';
import video from '../../images/video_play.png'
const Homecategort = ({ catdata }) => {
  const [selectedItem, setSelectedItem] = useState(null); // State to store the selected item
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  const openModal = (item) => { // Function to open the modal
    setSelectedItem(item);
    setShowModal(true);
  }

  const closeModal = () => { // Function to close the modal
    setShowModal(false);
  }
  var content = `<p><iframe allowfullscreen="" frameborder="0" height="360" src="https://www.youtube.com/embed/BIYPGJNgMNM" width="640"></iframe></p>`;

  return (
    <div>
      {Object.values(catdata).map(ele => {
        return (
          <div key={ele[0].category_name}>
            <TitleHeader title={ele[0].category_name} slug={ele[0].category_slug} viewall={"View all"} />
            <Row>
              {ele.slice(0, 3).map(items => {
                return (
                  <>
                  {
                    items.category_slug=="videos" ?


                    <Col key={items.id} lg={4} md={4} sm={4} className="imagecard-main-image">
                    <div onClick={() => openModal(items)}> {/* Ensure the onClick event is directly on a clickable element */}
                      <ImageCard
                        className={"carousel_rightimage imagessize"}
                        image={items.image}
                        discription={items.title}
                        category_name={items.category_name}
                        published_date={items.published_date}
                        video={"data"}
                      />
                    </div>
                  </Col>
                :
              
                  <Col key={items.id} lg={4} md={4} sm={4} className="imagecard-main-image">
                    <div > {/* Ensure the onClick event is directly on a clickable element */}

                    <NavLink to={`/Detail/${items.slug}`} className={"text-decoration-none"} >
                      <ImageCard
                        className={"carousel_rightimage imagessize"}
                        image={items.image}
                        discription={items.title}
                        category_name={items.category_name}
                        published_date={items.published_date}
                      />
                      </NavLink>
                    </div>
                  </Col>
                    }
                  </>
                )
              })}
            </Row>
          </div>
        )
      })}
      {/* Modal Component */}
      <Modal show={showModal} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          {selectedItem && (
            <div>
              <h5>{selectedItem.title}</h5>
              {/* <p>Category: {selectedItem.category_name}</p>
                            <p>Published Date: {selectedItem.published_date}</p> */}
              {/* Add more details as needed */}
            </div>
          )}
          {/* <Modal.Title>Item Details</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
        {selectedItem && selectedItem.body ? (
    <div dangerouslySetInnerHTML={{ __html: selectedItem.body }} className="poo_utube_video"/>
  ) : (
    <p>No content available</p>
  )}
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                </Modal.Footer> */}
      </Modal>
    </div>
  )
}

export default Homecategort;
