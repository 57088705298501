import React, { lazy, Suspense } from 'react';
import { ImageCard, TitleHeader, SmallComp, HomeBig_small_blogs } from '../../components/common'
import { Row, Col } from 'react-bootstrap';
// const HomeBig_small_blogs = lazy(() => import("../../components/common/HomeBig_small_blogs"))
const HomeCardSingleBlogs = ({ className, categorydatass, relateddata }) => {
    const firstArray = relateddata && relateddata.length > 0 ? relateddata.slice(0, 5) : [];
    const secondArray = relateddata && relateddata.length > 0 ? relateddata.slice(5, 10) : [];
    const thirdArray = relateddata && relateddata.length > 0 ? relateddata.slice(10, 15) : [];
    const businessarray = categorydatass && categorydatass.Business ? categorydatass.Business.slice(0, 5) : [];
    const Galleryarray = categorydatass && categorydatass.Gallery ? categorydatass.Gallery.slice(0, 5) : [];
    const Moviearray = categorydatass && categorydatass.Movie ? categorydatass.Movie.slice(0, 5) : [];
    const datasmall = [
        {
            image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
            category_name: "health",
            author_name: "Sridhar",
            discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
            published_date: "20/20/2039",
        },
        {
            image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
            category_name: "travel",
            author_name: "murali",
            discription: "Early tourists choices to the sea of Maldiv…",
            published_date: "20/20/2039",
        },
        {
            image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
            category_name: "health",
            author_name: "Sridhar",
            discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
            published_date: "20/20/2039",
        },
        {
            image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
            category_name: "travel",
            author_name: "murali",
            discription: "Early tourists choices to the sea of Maldiv…",
            published_date: "20/20/2039",
        },




    ]
    return (
        <div>
            <Row>
                {
                    relateddata &&
                    <>
                        <Row>
                            <Col lg={4} md={12} sm={12}>
                                <HomeBig_small_blogs arrayrelated={firstArray} />

                            </Col>
                            <Col lg={4} md={12} sm={12}>
                                <HomeBig_small_blogs arrayrelated={secondArray} />
                            </Col>
                            <Col lg={4} md={12} sm={12}>
                                <HomeBig_small_blogs arrayrelated={thirdArray} />
                            </Col>
                        </Row>
                    </>
                }
                {
                    categorydatass &&
                    <>
                        <Row>
                            <Col lg={4} md={12} sm={12}>
                                <div>
                                    {/* <TitleHeader title={categorydatass.Business[0].category_name + " News"} />
                                     */}
                                    {categorydatass.Business && categorydatass.Business.length > 0 &&
                                        <TitleHeader title={categorydatass.Business[0].category_name + " News"} />
                                    }
                                </div>

                                <HomeBig_small_blogs categoryreleteddata={businessarray} />

                            </Col>
                            <Col lg={4} md={12} sm={12}>
                                <div>
                                    {/* <TitleHeader title={categorydatass.Gallery[0].category_name + " News"} /> */}
                                    {categorydatass.Gallery && categorydatass.Gallery.length > 0 &&
                                        <TitleHeader title={categorydatass.Gallery[0].category_name + " News"} />
                                    }

                                </div>

                                <HomeBig_small_blogs categoryreleteddata={Galleryarray} />

                            </Col>
                            <Col lg={4} md={12} sm={12}>
                                <div>
                                    {/* <TitleHeader title={categorydatass.Movie[0].category_name + " News"} /> */}

                                    {categorydatass.Movie && categorydatass.Movie.length > 0 &&
                                        <TitleHeader title={categorydatass.Movie[0].category_name + " News"} />
                                    }
                                </div>
                                <HomeBig_small_blogs categoryreleteddata={Moviearray} />

                            </Col>
                        </Row>
                    </>
                }


                {/* {
                    relateddata && relateddata.length > 0 &&
                    relateddata.slice(0, 5).map(ele => {
                        return (

                            <Col lg={4} md={12} sm={12}>
                                {
                                    <div><TitleHeader title={"related posts"} /></div>
                                }
                                <HomeBig_small_blogs datasmall={ele} />
                            </Col>
                        )
                    })
                } */}

                {/* {
                    data5 && data5.length > 0 &&
                    data5.map(ele => {
                        return (
                            <Col lg={4} md={12} sm={12}>
                                {
                                    !className &&
                                    <div><TitleHeader title={"TRAVEL NEWS"} /></div>
                                }
                                <HomeBig_small_blogs datasmall={ele} />
                            </Col>
                        )
                    })
                } */}








            </Row>
        </div>
    );
};

export default HomeCardSingleBlogs;