import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Homecarousel, ImageCard } from '../../components/common';
// const Homecarousel = lazy(() => import("../../components/common/Homecarousel"));
// const ImageCard = lazy(() => import("../../components/common/ImageCard"));
// import Homecarousel = lazy(()=>import("../../components/common/Homecarousel"));
const HomeCarouselMain = ({ homecarousel }) => {

    // let carouselItems = [];
    // // Check if homecarousel exists and has the 'items' property before accessing it
    // if (homecarousel && homecarousel.items) {
    //     carouselItems = homecarousel.items;
    // }
    // let homecarouselremaing = {}
    // // Check if homecarousel exists and has the 'items' property before accessing it
    // if (homecarousel && homecarousel.remaining_items[0]) {
    //     homecarouselremaing = homecarousel.remaining_items[0];
    // }
    // useEffect (()=>{
    //     // getNewsDatalistdata();
    //     // carouselItems = homecarousel.items;
    // },[homecarousel]);
    // const [homeright,setHomeright]=useState({homecarousel.remaining_items[0]})
    // useEffect(()=>{
    //     setHomeright(homecarousel.remaining_items[0])
    // },[]);

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col lg={12} md={12} className=' home-maingghg'>
                   
                            {
                                homecarousel && homecarousel.items.length > 0 &&
                                <Homecarousel homecarouseldata={homecarousel.items} />
                            }







                    

                    </Col>
                 

                </Row>

            </Container>
        </div>
    );
};

export default HomeCarouselMain;