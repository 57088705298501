import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { StateContext } from "../components/context/ManageStates";
// import { TitleHeader, Singleimage, SmallComp } from '../components/common'
import { NavLink } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from 'react-responsive';
import DetailAction from "../redux/actions/DetailAction";
import CategoryAction from '../redux/actions/CategoryAction';
import RelatedAction from '../redux/actions/RelatedAction';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
const Singleimage = lazy(() => import("../components/common/Singleimage"));
const SmallComp = lazy(() => import("../components/common/SmallComp"));
const TitleHeader = lazy(() => import("../components/common/TitleHeader"));
const Category = () => {

    const { getdetaillistdata } = DetailAction()
    const { getSubcategoryPostdatalistdata } = CategoryAction();
    const { getRelatedPostslistdata } = RelatedAction()
    const category = useSelector(state => state.masterdata.cartdata);
    const hasmore = useSelector(state => state.masterdata.hasmore);
    const cartdisplay = useSelector(state => state.masterdata.cartdisplay);

    const page = useSelector(state => state.masterdata.page)
    const dispatch = useDispatch();
    // const [hasmaor, setHasmore] = useState(true)
    const { slugs } = useParams();
    const categorylength =
        category &&
        category.display_items &&
        category.display_items.posts &&
        category.display_items.posts.length > 0 && // Check if posts array exists and is not empty
        category.display_items.posts[0] &&
        category.display_items.posts.length;
    const cattitle =
        category &&
        category.display_items &&
        category.display_items.posts &&
        category.display_items.posts.length > 0 && // Check if posts array exists and is not empty
        category.display_items.posts[0] &&
        category.display_items.posts[0].category_name;
    // const tags = ["travel", 'health', 'aritucture', 'food'];getRelatedPostslistdata
    // useEffect(() => {
    //     dispatch(getSubcategoryPostdatalistdata());
    // },[category]);



    useEffect(() => {

        dispatch({
            type: 'setCartdata',
            payload: []

        });
        dispatch({
            type: 'setpage',
            payload: 1,

        });
        const input_data = {
            slug: slugs,
            page: 1,
        };
        dispatch(getSubcategoryPostdatalistdata(input_data));
    }, [slugs])

    // useEffect(()=>{


    // },[page])

    const fetchdata = () => {
        dispatch({ type: 'setpage', payload: page + 1 })
        const input_data = {
            slug: slugs,
            page: page + 1,
        };
        dispatch(getSubcategoryPostdatalistdata(input_data));
    }


    const data = [
        {
            image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
            category_name: "health",
            author_name: "Sridhar",
            discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
            published_date: "20/20/2039",
        },
        {
            image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
            category_name: "travel",
            author_name: "murali",
            discription: "Early tourists choices to the sea of Maldiv…",
            published_date: "20/20/2039",
        },
        {
            image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
            category_name: "health",
            author_name: "Sridhar",
            discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
            published_date: "20/20/2039",
        },
        {
            image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
            category_name: "travel",
            author_name: "murali",
            discription: "Early tourists choices to the sea of Maldiv…",
            published_date: "20/20/2039",
        },
        {
            image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
            category_name: "travel",
            author_name: "murali",
            discription: "Early tourists choices to the sea of Maldiv…",
            published_date: "20/20/2039",
        },



    ]



    const handleClick = (index, item) => {
        // const clickedCategory = category.find((items, idx) => items.id === item);
        // setSelectedcategory(clickedCategory);

    }

    // setSelectedButton(index)

    const isMobile = useMediaQuery({ query: '(min-width: 991px)' })
    // const isMobile= useMediaQuery({query:"max-width:991px"})
    return (
        <div>
            {

                Object.keys(category).length > 0 ?
                    <>
                        <InfiniteScroll
                            dataLength={cartdisplay.length}
                            hasMore={hasmore}

                            next={() => {

                                fetchdata()
                                // dispatch({ type: 'setFilterPage', payload: filterPage + 1 })
                            }}
                            scrollThreshold={"50%"}
                            //   next={()=>{
                            //     dispatch({ type: 'setpage', payload: page + 1 })
                            //   }}

                            endMessage={<></>}

                            style={{ overflow: "hidden" }}
                        >
                   

                            <Row>

                                {
                                    cartdisplay && cartdisplay.length > 0 && cartdisplay.slice(0, category.length).map((ele, index) => {
                                        return (
                                            <Col lg={4} md={12} className='top-category-space'>
                                                {
                                                    <NavLink to={`/detail/${ele.slug}`} className="text-decoration-none" >
                                                        <Suspense>
                                                            {/* <div onClick={() => handleClick(index, ele.postid)}> */}
                                                            <Singleimage image={ele.bannerimage} discription={ele.title} author_name={ele.authorname} published_date={ele.createdon} content={ele.body} />
                                                            {/* <Singleimage image={ele.image} discription={ele.discription} author_name={ele.author_name} published_date={ele.published_date} content={ele.content}  onClick={() => handleClick(ele.id)} /> */}
                                                            {/* </div> */}

                                                        </Suspense>
                                                    </NavLink>
                                                }

                                            </Col>
                                        )
                                    })
                                }

                            </Row>
                        </InfiniteScroll>

                      
                    </>

                    : <div className='custom-height'></div>

            }

        </div>
    );
};

export default Category;