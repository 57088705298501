import React, { Suspense, lazy, useEffect, useContext } from 'react';
import {  HomeCarouselMain,HomeAbout,Homecategort, HomeTechnologyNews, HomeCardSingleImagewithBlogs, HomeCardSingleBlogs } from '../components/common'
import { UseData } from '../hooks';
import {initainitialState} from '../redux/reducers/MasterReducer'

// import { StateContext } from "../components/context/ManageStates";
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
// const HomeCarouselMain = lazy(() => import("../components/common/HomeCarouselMain"));
// const HomeTechnologyNews = lazy(() => import("../components/common/HomeTechnologyNews"));
// const HomeCardSingleBlogs = lazy(() => import("../components/common/HomeCardSingleBlogs"));
const Home = ({homedata}) => {
    // const { homedata } = useContext(initainitialState);
    // const { getNewsDatalistdata } = UseData();
//     const dispatch = useDispatch();
//   const { getNewsDatalistdata } = Homedata()

//   useEffect(()=>{
//     dispatch(getNewsDatalistdata())
//   },[])
   

    return (
        <>
            {
                Object.keys(homedata).length > 0 ?

                //   homedata ?
                < div >
            <HomeCarouselMain homecarousel={homedata.carouselList} />
        
        <HomeAbout  catdata={homedata.postcategorydataList}/>
           
       <Homecategort  catdata={homedata.postcategorydataList}  />


        </div >:
        <div className='custom-height'></div>
}
</>
    );
};

export default Home;