import React from 'react';
import { FaUser } from "react-icons/fa";
import { GiTimeBomb } from "react-icons/gi";
import videos from '../../images/video_play.png'
const ImageCard = ({ classname, image,video, discription, author_name, category_name, published_date, dissize }) => {
    return (
        <div className={`ImageCard-main-div ${classname}`}>

            <div className='images-hover'>
                <div className='images-hover-sub-div'>
                    <div className={`Singleimage-main-image-div imagecard-relative ${classname}`}>


                        <img referrerPolicy="no-referrer" className={`  image-homecarousel  seeee `} src={localStorage.getItem("cdn") + image}   ></img>
                        <div className='utf_featured_post'>
                            <div className={`imagecard-absolute imagecard-side  ${classname} `}>
                                <div className='ImageCard-category_name'><div className='category_name'>{category_name}</div></div>
                                <div className={`ImageCard-discription ${dissize}`}>{discription}</div>
                                <div className='ImageCard-author-date'>
                                    {/* <div className='imagecard-author'><FaUser className='imagecard-author-icon' />  {author_name}</div> */}
                                    {
                                        // published_date.length > 0 &&
                                        // <div className='imagecard-author'><GiTimeBomb className='imagecard-author-icon' />  {published_date}</div>
                                    }

                                </div>
                            </div>
                        </div>
                        {
                            video &&
                            <div>
                            <img className='video-image' src={videos}/>
                        </div>

                        }
                       

                    </div>

                </div>
            </div>










        </div>
    );
};

export default ImageCard;