export const HOME = '/';
export const detail = '/detail/:slug';
// export const BundellDetail = '/bundell-details/:slug';
// export const CartDetail = '/cart';
// export const ForgotPassword = '/password-reset';
// export const Register = '/register';
// export const Login = '/login';
// export const BuisnessRegister = '/buisness-register';
// export const checkout = '/checkout';
// export const myaccount = '/my-account';
// export const productCat = '/product-cat';
// export const searchResult = '/search-result/:slug';
// export const productsSubCategory = '/products-subcategory/:slug';
// export const productsCategory = '/products-category/:slug';
// export const BundellProducts = '/bundell-products';
// export const MyOrders = '/my-orders';
// export const addresses = '/addresses';
// export const wishlist = '/wishlist';
// export const Searchsuggestion = '/searchsuggestion';
// export const profile = '/my-account/profile';
// export const subscription = '/subscription';
// export const OrderSuccessPage = '/order-success/:slug';
// export const myAccountSubscription = '/my-account/subscription';
// export const OrdeDetailsPage = '/order-details/:slug1/:slug2';



export const Category='/Category/:slugs';
export const SubCategory='/SubCategory/:slug';