import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import SmallComp from './SmallComp';
import aboutimag from '../../images/col-bgimage-2.png'
const HomeAbout = ({catdata}) => {
    const data = [
        {
            title: "about",
            discription: "He is present indian captain  Rohit Gurunath Sharma (born 30 April 1987) is an Indian international cricketer who currently captains the India national cricket team across all formats. He is a right-handed batsman. Considered one of the best batsmen of his generation and one of the greatest opening batters of all time,[3] Sharma is known for his timing, elegance, six-hitting abilities and leadership skills. Sharma holds several batting records which famously include most sixes in international cricket,[a] most double centuries in ODI cricket (3), most centuries at Cricket World Cups (7) and joint most hundreds in Twenty20 Internationals (5). Rohit Sharma is the first player to score 5 T20I centuries.[5] He plays for Mumbai Indians in IPL and for Mumbai in domestic cricket",
            id: 1,
        },
        {
            title: "awards",
            discription: "He taken lots of awards On 15 April 2019, Sharma was appointed vice-captain of India's squad for the 2019 World Cup in England.[46] In the opening match against South Africa, he scored 122, including his 12,000th run in international cricket.[47][48] He followed it up with centuries against Pakistan, England and Bangladesh. In the match against Sri Lanka, hitting yet another century, he became the first batsman to score five centuries in a single World Cup tournament,[49] and equalled Tendulkar's record for the most centuries (6) in all World Cup matches.[50] Sharma totalled 648 runs in the tournament to finish as the leading run-scorer and win the ICC's Golden Bat award, the third Indian player to do so.",
            id: 2,
            items: [
                {
                    image: '/baner7.png',
        
                },
         
            ]
        }
    ];
    const [aboutdis, setAboutdis] = useState(data[0]);
    const [selectedButton, setSelectedButton] = useState(0);
    const handleclick = (index) => {
        const selected = data.find((item, idx) => idx === index);
        setAboutdis(selected);
        setSelectedButton(index)
    }
    return (
        <div className='photo-section'>
            <Row>
                <Col lg={6} md={12} sm={12}>
                    <img className='about-img' src={aboutimag} />

                </Col>
                <Col lg={6} md={12} sm={12}>
                    <Row>
                        {
                            data.map((ele, index) => {
                                return (
                                    <Col lg={6} md={6} sm={6} xs={6} className='about-titles'>
                                        <div className={`aboutselected ${selectedButton === index ? "selectedactiveabout" : ""}`} onClick={() => handleclick(index)}>
                                            {ele.title}
                                        </div>

                                    </Col>
                                )

                            })
                        }
                        <Col lg={12}>
                            {

                                <div>
                                    {
                                        aboutdis.hasOwnProperty("items") ?
                                            <div>
                                                {
                                                    catdata.Awards.slice(0,5).map(ele1 => {
                                                        return (
                                                            <NavLink to={`/Detail/${ele1.slug}`} className={"text-decoration-none"} >
                                                            <div className='awards-image'>
                                                               <div>
                                                                <img className='awards-image-image' src={ localStorage.getItem("cdn") + ele1.image}></img>
                                                               </div>
                                                               <div className='awards-title'>
                                                                {ele1.title}

                                                               </div>
                                                            </div>
                                                            </NavLink>
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            // aboutdis.discription
                                            <div className='about-title'>
<p>
Dr. Pujitha Devi Suraneni is a Senior Consultant at the Rainbow-Birth Right-Financial District Branch in Hyderabad, with over 14yrs of experience in the obstetrics and gynecology field. She has also worked as a Senior Consultant at KIMS Cuddles Hospital and as a Consultant at Birth Right by Rainbow Hospitals at kondapur branch and Archana Hospital in Hyderabad. She is also a Professor at Malla Reddy Institute of Medical Sciences in hyderabad. 
</p>
<p>Dr. Pujitha Devi is experienced in various procedures such as Normal labour, LSCS, D&C, and known for managing high-risk obstetric cases. She also has expertise in performing highly complicated minimal access surgeries- laparoscopic hysterectomy, myomectomy, ovarian cystectomy, endometriotic cystectomy, tubectomy (family planning), all hysteroscopic procedures- polypectomy, septal resection, myomectomy. Vaginal procedures like hysterectomies, cystocele repairs, vaginal hymenectomy, vaginoplasties, cosmetic surgeries. All gynaecological robotic surgeries.</p>
  <p>To her credit she has successfully removed 29 fibroids from an international women and retained uterus. Done</p>
  <p>Laparoscopic Myomectomies and lap hysterectomies for more than 20 cms fibroids and ovarian cysts.</p>
  
  <p>Dr. Pujitha Devi Suraneni has extensive qualifications in obstetrics and gynecology. She earned her MBBS degree from Kamineni Institute of Medical Sciences in Narketpally, Andhra Pradesh, and her postgraduate MS degree  in Obstetrics and Gynaecology from Chalmeda Ananda Rao Institute of Medical Sciences in Karimnagar, Andhra Pradesh. </p>
   <p>In addition to her academic degrees, Dr. Pujitha Devi has pursued further training and specializations. She has completed a fellowship in minimal access surgery (FMAS) and a fellowship at the International College of Robotic Surgeons(FICRS). She has also received certification in laparoscopic and endoscopic surgeries. Furthermore, she completed a "Hands-on training program for laparoscopic radical hysterectomy/total laparoscopic hysterectomy."</p>
   <p>She has received various awards and recognitions for her services, including Best Gynecologists Award from Hi9 Private Media, Best Doctor Award from Friends Association Hyderabad, and Rastriah Puraskar: National Award for Services as a Doctor by the National North Delhi Cultural Academy. </p>
   <p>She has also been honored with the South India Women Achievers Award for Excellence in Medical Services, the Super Women of Decade Award by Hi 9 Private Media, the Hi9 Hero Awards for COVID Warriors, and the Dr. A.P.J. Abdul Kalaam Award for Health and Medical Health Excellence. Dr. Pujitha Devi has also received the Times Excellence Award for Service Excellence in Obstetrics and Gynecology and the Nari Spandana Puraskar Award by the Spandana Eda International Foundation.</p>
   
   </div>

                                    }
                                </div>
                            }

                        </Col>
                    </Row>


                </Col>
            </Row>
        </div>
    )
}
export default HomeAbout;