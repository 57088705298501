import React from 'react';
import { ImageCard, SmallComp } from '../../components/common';
import { NavLink } from 'react-router-dom';
const HomeBig_small_blogs = ({ datasmall,arrayrelated,categoryreleteddata }) => {
  

  const [firstarraydata, ...restArrayData] = Array.isArray(arrayrelated) ? arrayrelated : [];
  const [categoryfirstarraydata, ...categoryrestArrayData] = Array.isArray(categoryreleteddata) ? categoryreleteddata : [];
  
  return (
    <div>
      <div className='HomeCardSingleImagewithBlogs-imagecard'>
   
{
  firstarraydata &&
  <NavLink to={`/Detail/${firstarraydata.slug}`} className={"text-decoration-none"} >

  <ImageCard
  className={"carousel_rightimage imagessize"}
  image={firstarraydata.bannerimage}
  discription={firstarraydata.title}
  author_name={firstarraydata.authorname}
  category_name={firstarraydata.category_name}
  published_date={firstarraydata.createdon}
/>
</NavLink>
}
{
    categoryfirstarraydata &&
    <NavLink to={`/Detail/${categoryfirstarraydata.slug}`} className={"text-decoration-none"} >
    <ImageCard
    className={"carousel_rightimage imagessize"}
    image={categoryfirstarraydata.image}
    discription={categoryfirstarraydata.title}
    author_name={categoryfirstarraydata.author_name}
    category_name={categoryfirstarraydata.category_name}
    published_date={categoryfirstarraydata.published_date}
  />
  </NavLink>
}
       
      
      </div>



      <div className='HomeCardSingleImagewithBlogs-smallcomp'>
        {

restArrayData &&  restArrayData.length > 0 &&
restArrayData.map(ele => {
            return (
              <NavLink to={`/Detail/${ele.slug}`} className={"text-decoration-none"} >
              <SmallComp className={`HomeCardSingleImagewithBlogs-category  `} title={"category"}  image={ele.bannerimage} published_date={ele.createdon} category_name={ele.category_name} discription={ele.title} />
              </NavLink>
            )
          })
        }

{

categoryrestArrayData &&  categoryrestArrayData.length > 0 &&
categoryrestArrayData.map(ele => {
            return (
              <NavLink to={`/Detail/${ele.slug}`} className={"text-decoration-none"} >
              <SmallComp className={`HomeCardSingleImagewithBlogs-category  `} title={"category"}  image={ele.image} published_date={ele.published_date} category_name={ele.category_name} discription={ele.title} />
              </NavLink>
            )
          })
        }



      </div>

    </div>
  );
};

export default HomeBig_small_blogs;