import React, { useContext, useEffect } from 'react';
import { Header,Footer,Loder } from '../common';
import { Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
const DesktopLayout = ({ children }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });

    return (
        <>
       
            <Header />
            {
                // !isMobile ?
                <Container fluid>
                <div className='gsdgsg'>
                {children}
                </div>        
            </Container>
            // : 
            // <div>
            //      {children}
            // </div>
            
            }
           
            {
                // !isMobile &&
                <Footer/>
            }
     <Loder />
    
        </>
    );
};

export default DesktopLayout;