import React, { useState } from 'react';
import { FaUser } from "react-icons/fa";
import { IoSearch, IoClose, IoCaretDownSharp } from "react-icons/io5";
import { LuMenu } from "react-icons/lu";
import { GoSquareFill } from "react-icons/go";
import { Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Sidebar } from '../../components/common'
import { NavLink } from 'react-router-dom';
import imagelogo from '../../images/drpujitha_logo.webp';

import { useSelector } from 'react-redux';
import { NavDropdown } from 'react-bootstrap';
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { useDispatch } from 'react-redux';
const Header = () => {
    const head = ["home", "news", "earth", "real", "sports", "worklife",]
    const dispatch = useDispatch();
    const navdata = useSelector(state => state.masterdata.navdata);
    const [selectedOption, setSelectedOption] = useState(null);
    const langdata = useSelector(state => state.masterdata.langdata);
    const slectedkeys = useSelector(state => state.masterdata.slectedkeys);
    const keysdata = langdata  && langdata[0]
        ? Object.keys(langdata[0])
        : [];
    const [data, setData] = useState(false);
    const ismobile = useMediaQuery({ query: '(min-width: 991px)' })
    const Handle = () => {
        setData(!data)
    }
    const [dataindex, setDataindex] = useState(0);
    const Clicks = (id) => {
        setDataindex(id)
        
    }
    const clicked = (data) => {
        // alert(data)
        dispatch({
            type: 'setslectedkeys',
            payload: data

        });
        setSelectedOption(data);
    }


    return (
        <div  className='main-header'>
              <div className='header-main-div-company'>
    
    {
        !ismobile ?
   
<>
<div className='header-logo'>

<NavLink  className={"text-decoration-none"}  to="/">
<img referrerPolicy="no-referrer" className='header-logo-image' src={imagelogo} alt="Dr. Pujitha Devi Logo" />

</NavLink>

</div>
<div className='header-main'>
        <div className='header-name'>
        Dr.Pujitha Devi Suraneni
     </div>
     <div className='header-colification'>MBBS,MS(Obs & Gynae),FMAS,FICRS</div>
     <div className='headcontant'>Senior consultant,High-Risk Obstetrician,</div>
     <div className='headcontant'>Laparoscopic and Robotic Surgeon</div>
     </div>
</>:
  <>
  <Col lg={4} className='header-main  heder-contentent-header '>
          <div className='header-name'>
          Dr.Pujitha Devi Suraneni
       </div>
       <div className='header-colification'>MBBS,MS(Obs & Gynae),FMAS,FICRS</div>
       <div className='headcontant'>Senior consultant,High-Risk Obstetrician,</div>
       <div className='headcontant'>Laparoscopic and Robotic Surgeon</div>
       </Col>
       
       <Col lg={4} className='header-logo  heder-contentent-logo'>
  
  <NavLink  className={"text-decoration-none"}  to="/">
      <img referrerPolicy="no-referrer" className='header-logo-image' src={imagelogo}></img>
  </NavLink>
  
  </Col >
       <Col lg={4} className='header-contact-logolist'>
       <NavLink  className={"text-decoration-none"}  to="https://www.instagram.com/drpujithadevisuraneni/" target="_blank">
       <FaInstagramSquare className='header-contact-logo'/> 
       </NavLink>
       <NavLink  className={"text-decoration-none"}  to="https://www.facebook.com/DrPujithaDeviSuraneni" target="_blank">
       <FaFacebook className='header-contact-logo'/> 
       </NavLink>
       <NavLink  className={"text-decoration-none"}  to="https://www.youtube.com/@PujithaDeviSuraneni" target="_blank">
       <FaYoutube className='header-contact-logo'/> 
       </NavLink>
       </Col>
  
       </> 
    
}





  
     {/* <div>

     </div> */}
     </div>
           


            <div className='header-below'>
                {/* <div className='header-below-title'>NEWS</div> */}
                {
                    ismobile ?
                        <div className='header-below-list'>
                             
                            
                            <div className='dgfdh'>

                               



                                {navdata.map((ele, index) => (

                                    <div key={ele.id}>
                                        <NavLink className={"text-decoration-none"} to={`/Category/${ele.slug}`}>
                                            <span
                                                className={`header-below-list-items ${dataindex === ele.id ? 'active' : 'disable'
                                                    }`}
                                                onClick={() => {
                                                    Clicks(ele.id);
                                                }}
                                            >
                                                <span className='ftdfdf'></span> {ele.name}
                                            </span>
                                        </NavLink>
                                    </div>
                                ))}

                               
                            </div>
      
                            {/* <span className='header-below-list-items header-below-list-more'> more <IoCaretDownSharp  /></span> */}
                        </div>
                        : <div ><Sidebar /></div>
                }

            </div>

        </div >
    );
};

export default Header;